import React from "react"
import Nav from "../components/nav"
import Footer from "../components/footer"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicePage = ({ data, location }) => {
  const params = new URLSearchParams(location.search);
  var url_param = location.search;
  var url_param2;

  var utm_campaign = params.get("utm_campaign");
  var utm_source = params.get("utm_source");
  var utm_medium = params.get("utm_medium");
  var utm_content = params.get("utm_content");

  if(url_param){
    url_param = url_param.replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/\?/g, '&');
  }

  if(url_param){
    url_param = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^\w\s&=?]/gi, '').replace(/\s/g, '');
    url_param2 = url_param.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/\?/g, '&');
  }

  if(utm_campaign) {
    utm_campaign = utm_campaign.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_campaign = ""
  }
  if(utm_source) {
    utm_source = utm_source.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_source = ""
  }
  if(utm_medium) {
    utm_medium = utm_medium.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_medium = ""
  }
  if(utm_content) {
    utm_content = utm_content.replace(/<script\b[^<](?:(?!<\/script>)<[^<])*<\/script>/gi, "").replace(/[^&?=a-zA-Z0-9]/g, "").replace(/\s/g, '')
  } else {
    utm_content = ""
  }

  var url_param3 = "?utm_campaign="+utm_campaign+"&utm_source="+utm_source+"&utm_medium="+utm_medium+"&utm_content="+utm_content

  if (typeof window !== 'undefined') {
      window.addEventListener('message', function(event) {
        if (event.data.action === 'scrollToEmptyField') {
          var iframe = document.getElementById('iframe');
          if (iframe) {
              iframe.scrollIntoView();
          }
        }
    });
  }

  return (
    <Layout >
      <Nav header="nomenu" urlParam={url_param3}/>
      <div className="no-masthead relative"></div>

      <section className="section sectionFirst">
        <div className="container py-10">
          <iframe
            id="iframe"
            className="w-full h-[2300px] md:h-[2500px]"
            src="https://remotework.sg/bmw-rsvp/servicebooking-AK.php"
            border="0"
            frameborder="0"
          ></iframe>
        </div>
      </section>
      <script
        dangerouslySetInnerHTML={{
          __html: `window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
        window._adftrack.push({
            HttpHost: 'asia.adform.net',
            pm: 1824002,
            divider: encodeURIComponent('|'),
            pagename: encodeURIComponent('EA Service Booking Landing Page')
        });
        (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();`,
        }}
      ></script>
      <noscript>
        <p className="border-0 p-0 m-0">
          <img
            src="https://asia.adform.net/Serving/TrackPoint/?pm=1824002&ADFPageName=EA%20Service%20Booking%20Landing%20Page&ADFdivider=|"
            width="1"
            height="1"
            alt=""
          />
        </p>
      </noscript>
      <script
        dangerouslySetInnerHTML={{
          __html: `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '608633623033362');
fbq('track', 'PageView');`,
        }}
      ></script>
      <noscript>
        <img
          height="1"
          width="1"
          className="hidden"
          src="https://www.facebook.com/tr?id=608633623033362&ev=PageView&noscript=1"
        />
      </noscript>
      <Footer urlParam={url_param} />
    </Layout>
  )
}

export default ServicePage

const seoDetails = {
  title: "Eurokars Auto | Service Booking",
  description: "Request for service appointment.",
  keywords: "Service Booking",
  image: "og-eka-contact-us.jpg",
}


export const Head = () => (
  <>
    <title>{seoDetails.title}</title>
    <meta property="og:type" content="website" />
    <meta
      name="description"
      property="description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="og:description"
      property="og:description"
      content={seoDetails.description}
    ></meta>
    <meta
      name="keywords"
      property="keywords"
      content={seoDetails.keywords}
    ></meta>
    <meta
      name="og:url"
      property="og:url"
      content={`${process.env.GATSBY_BASE_URL}`}
    ></meta>
    <meta
      name="og:image"
      property="og:image"
      content={`${process.env.GATSBY_BASE_URL}/img/${seoDetails.image}`}
    ></meta>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>

  </>
)
